import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import Vue from "vue";
// Notification

import axios from "@axios";

export default function leavesApprove() {





  const reftransferListTable=ref(null)
  const mainServiceList=ref('')
  const mainServiceOption=ref([])
  // Table Handlers
  const tableColumns = [
    { key: "id", label: " الرقم",formatter: title,},
    
    { key: "user_id", label: "الموظف",formatter: title,},
    { key: "body", label: "الوصف",formatter: title,},
    {
        isActive: false, 
        key: "leave_type_id.name",
        label: "نوع الاجازة",
        formatter: title,
  
      },
    
    {
      isActive: false, 
      key: "duration",
      label: "المدة",
      formatter: title,

    },
    {
      isActive: false, 
      key: "start",
      label: "تاريخ البدء",
      formatter: title,

    },
    
  
    
  

    { key: "actions", label: "الخيارات" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const TransferType=ref('outgoing')
  const dataMeta = computed(() => {
    const localItemsCount = reftransferListTable.value
      ? reftransferListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    reftransferListTable.value.refresh();
  };
  watch(
    [
        TransferType,
      currentPage,
      perPage,
      searchQuery,

    ],
    () => {
      refetchData();
    }
  );

  const responseByEmployee= (id, statuts) => {
    // }else{
    store
      .dispatch('calendar/responseByEmployee', {

        assigner_approve: statuts,
        id,
       

      })
      .then(response => {
        refetchData()
        Vue.swal({
          title: ' تم  بنجاح',

          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
      .catch(() => {
        Vue.swal({
          title: `${response.data.message}`,
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    // }
  }
  const fetchListLeaverApprove = (ctx, callback) => {
    let userData= JSON.parse(localStorage.getItem("userData"));
    //console.log("userData",userData.roles[0].id)
    
    // }else{
    store
      .dispatch("calendar/fetchListLeaverApprove",{
        
        page: currentPage.value,
        page_size:perPage.value,
      

      })
      .then((response) => {
        const { data, total } = response;
        //console.log(response.data)
        callback(data);
        totalUsers.value = total;
      })
      .catch(() => {
        Vue.swal({
              title: "حدثت مشكلة في استرجاع البيانات",
      icon: "error",

        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      });
    // }
  };





  return {
    fetchListLeaverApprove,
    mainServiceOption,
    responseByEmployee,
    mainServiceList,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,

    reftransferListTable,
    TransferType,

    refetchData,

  };
}
